import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Pagination = ({ numPages, basePath, slug }) => {
  const getPageNumberPath = (currentIndex) => {
    if (currentIndex === 0) {
      return `/${basePath}/${slug}/`
    }

    return `/${basePath}/${slug}/` + (currentIndex + 1)
  }

  return (
    <PaginationContainer>
      {Array.from({ length: numPages }, (_, index) => {
        return (
          <Link
            key={index + 1}
            to={getPageNumberPath(index)}
            activeClassName="current-page"
          >
            {index + 1}
          </Link>
        )
      })}
    </PaginationContainer>
  )
}

export default Pagination

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  a {
    padding: 0 5px;
    margin: 0 5px;
    color: #5b5d5e;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
  }

  .current-page {
    color: #149be1;
    border-bottom: 2px solid #149be1;
    pointer-events: none;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    a {
      font-size: 14px;
    }
  }
`
